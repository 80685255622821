import splide, { customSplide } from "../utils/splide";

const banner = document.getElementById("banner");
if (banner) {
    const bannerSplide = customSplide(banner, {
        type: "fade",
        rewind: true,
        perPage: 1,
        autoplay: true,
        fixedHeight: "579px",
        speed: 2000,
        lazyLoad: "sequential",
        pagination: false,
        arrows: banner.dataset.slides > 1 ? true : false,
        breakpoints: {
            1200: {
                pagination: true,
                arrows: false,
            },
        },
    });
    bannerSplide.mount();
}

const clinic = document.getElementById("clinic");
if (clinic) {
    const clinicSplide = splide(clinic, {
        perPage: 1,
        breakpoints: {},
    });
    clinicSplide.mount();
}

const news = document.getElementById("news");
if (news) {
    const newsSplide = customSplide(news, {
        type: "slide",
        rewind: true,
        gap: "2em",
        perPage: 3,
        autoplay: true,
        speed: 2000,
        lazyLoad: "sequential",
        pagination: false,
        arrows: true,
        breakpoints: {
          1200: {
              perPage: 2,
              arrows: false,
              pagination: true,
          },
          600: {
              perPage: 1,
          },
        },
    });
    newsSplide.mount();
}

const covenants = document.getElementById("covenants");
if (covenants) {
    const covenantSplide = splide(covenants, {
        perPage: 5,
        breakpoints: {
            1200: {
                perPage: 3,
                arrows: false,
                pagination: true,
            },
            600: {
                perPage: 1,
            },
        },
    });
    covenantSplide.mount();
}
